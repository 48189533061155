<template>
  <div>
    <m-bread-crumb/>

<section class="about spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title about-title">
                        <u><h2>Política de Privacidade e Protecção de Dados Pessoais</h2></u>
                        <br/> 
                        <br/>
                        <!-- <p style="text-align:justify">
                            A presente Política de Privacidade e de Protecção de Dados Pessoais, alinhada com a das empresas do Grupo Caixa Geral de Depósitos, no âmbito do Regulamento (EU) 2016/679, de 27 de Abril (Regulamento Geral sobre a Proteção de Dados, abreviadamente RGPD), entrado
em vigor em 25 de Maio de 2018, relativo à proteção de pessoas singulares, no que diz
respeito ao tratamento de dados pessoais e à livre circulação desses dados, explicita os termos
em que o BCI procede ao tratamento de dados pessoais dos Clientes, bem como os direitos
que podem exercer, de acordo com o disposto na legislação nacional aplicável em matéria de
privacidade e protecção de dados, bem como com o disposto no RGPD, nas situações de
aplicabilidade das suas regras e princípios a Clientes, Colaboradores ou a fornecedores do
Banco.
Esta Política complementa o disposto nas Condições Gerais de Abertura de Conta de Pessoas
Singulares e nos demais instrumentos contratuais que vinculam os clientes ao Banco, no
âmbito desta matéria e constitui o compromisso do BCI quanto à protecção dos dados dos seus
Clientes.
                            <br/> 
                            <br/>
                            Para facilitar a compreensão desta Política, são utilizadas as seguintes definições:<br/>
a) Dados Pessoais: qualquer informação relativa a uma pessoa singular identificada ou
identificável (o "titular dos dados"); é considerada identificável uma pessoa singular que
possa ser identificada, direta ou indiretamente, em especial por referência a um identificador,
como por exemplo um nome, um número de identificação, dados de localização,
identificadores por via eletrónica ou a um ou mais elementos específicos da identidade
física, fisiológica, genética, mental, económica, cultural ou social dessa pessoa singular;
                            <br/>
                            b) Tratamento de dados pessoais: é uma operação ou um conjunto de operações
efectuadas sobre dados pessoais ou sobre conjuntos de dados pessoais, por meios
automatizados ou não automatizados, tais como a recolha, o registo, a organização, a
estruturação, a conservação, a adaptação ou alteração, a recuperação, a consulta, a
utilização, a divulgação por transmissão, difusão ou qualquer outra forma de
disponibilização, a comparação ou interconexão, a limitação, o apagamento ou a destruição;
                            <br/> 
                            c) Responsável pelo Tratamento dos Dados Pessoais: pessoa singular ou coletiva que
determina as finalidades e os meios do tratamento de dados pessoais.
                            
                            <br/> 
                            d) Subcontratante: pessoa singular ou coletiva que trata os dados pessoais por conta do
BCI no contexto de prestação de serviços, formalizada através de Contrato.
                            
                            <br/> 
                            e) Autoridade competente: autoridade pública moçambicana, independente, a quem
compete fiscalizar a correta aplicação da legislação sobre proteção de dados pessoais.
                            
                            <br/> 
                            e) Autoridade competente: autoridade pública moçambicana, independente, a quem
compete fiscalizar a correta aplicação da legislação sobre proteção de dados pessoais.
                            <br/> 
                            f) Cookies: ficheiros informáticos que contêm uma sequência de números e letras que
permitem identificar de forma única o dispositivo de acesso à internet de um utilizador, mas
podem conter outras informações tais como as suas preferências de navegação num
determinado site. Os cookies são descarregados através do browser para o dispositivo de
acesso à internet (computador, telemóvel, tablet, etc.) quando se acede a determinados
sites;
                            <br/>
III. Entidade Responsável pelo tratamento de Dados
A entidade responsável pelo tratamento dos dados é o o BCI.
                            <br/> 
                            III. Entidade Responsável pelo tratamento de Dados
A entidade responsável pelo tratamento dos dados é o o BCI.
                            <br/> 

                            </p> -->
                    </div>
                </div>
            </div>
            
            
            
        </div>
    </section>
    
    

    
  </div>
</template>

<script>
import MBreadCrumb from "@/components/Layout/BreadCrumb";

export default {
  name: "TermsConditions",
  components: { MBreadCrumb }
}
</script>

<style scoped>

</style>